body {
  min-height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background-color: #70777a !important;
  background-image: url('../img/background-images/AdobeStock_50698443-min.jpeg') !important;
}

//fonts

h1, h2, h3, h4, h5 {
  font-family: "acumin-pro-condensed", sans-serif;
  font-weight: 700;
  //font-size: 28px;
  line-height: 1.1;
}

p {
  line-height: 140%;
  font-size: 15px;
}

ul, ol {
  font-size: 15px;
}

blockquote{
  font-size: 15px;
  p{
    font-size: 15px;
  }
  cite{
    font-size: 13px;
    padding-top: 10px;
  }
}

//navigation

.nav-bar {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: relative;
  height: 55px;
  .nav-open-box {
    background-color: #1779ba;
    top: 0;
    right: 0;
    position: absolute;
    padding: 15px 18px 15px 18px;
    height: 55px;
    width: 55px;
    &:hover {
      background-color: #126195;
      transition: 0.25s;
      cursor: pointer;
    }
  }
}

ul.accordion-menu {
  li {
    &:hover {
      background-color: #8CC63F;
    }
    a {
      color: #FFFFFF;
      padding-left: 25px;
      padding-right: 25px;
    }
    ul.nested {
      background-color: #A3D165;
      li {
        a {
          padding-left: 35px;
          padding-right: 25px;
        }
      }
    }
    ul.connect {
      background-color: #FFFFFF;
      li {
        &:last-child {
          padding-bottom: 30px;
        }

        &:hover {
          background-color: transparent;
        }
        h4 {
          font-size: 24px;
          font-family: "acumin-pro-condensed", sans-serif;
          font-weight: 700;
          color: #8CC63F;
          padding-left: 25px;
          padding-top: 20px;
          margin-bottom: 10px;
          a {
            padding-bottom: 0;
          }
        }
        p {
          padding-left: 25px;
          margin-bottom: 0;
          padding-right: 25px;
        }
        a {
          color: inherit;
          padding-left: 0;

        }
        .textlink {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
}

//footer

.black-bar {
  background-color: #000000;
}

//page



.header-image {
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.bio-image {
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
  width: 100%;
}

.page-header {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  color: #FFFFFF;
  
}

.page-subheader {
  padding-left: 15px;
  padding-right: 15px;
}

.body-top{
  padding-top: 20px;
  @media screen and (max-width: 39.9375em) {
    padding-top: 0;
  }
}

.body-copy {

  h1{
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
  }

  h2 {
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
  }

  h3 {
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
  }
 
  h4 {
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
  }

  h5 {
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
  }

  p {
    padding-left: 15px;
    padding-right: 15px;
  }

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

}

.white-box {
  background-color: #FFFFFF;
  padding: 15px;
  margin-top: 30px;
  @media screen and (max-width: 39.9375em) {
    margin-top: 10px;
  }
  .picture {
    min-height: 500px;
    @media screen and (max-width: 39.9375em) {
      min-height: 0;
    }
    width: 100%;
    background-color: #8a8a8a;
    background-image: url("../img/home/john-training-1.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    .green-box {
      background-color: #8CC63F;
      padding: 40px;
      margin: 20px;
      @media screen and (max-width: 39.9375em) {
        margin: 0;
      }
      h1 {
        color: #FFFFFF;
        margin-bottom: 10px;
      }
      img {
        width: 100%;
      }
    }
  }
  p {
    line-height: 140%;
    font-size: 15px;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.green {
  background-color: #8CC63F;
  padding-bottom: 20px;
}

.page-top-margin {
  margin-top: 20px;
  @media screen and (max-width: 74.9375em) {
    margin-top: 0;

  }
}

.forms {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  @media screen and (max-width: 63.9375em) {
    padding-top: 10px;
    padding-right: 20px;
  }
}

.download-icon{
  font-size: 40px; 
  padding-right: 10px;
}

table {
    display: inline-block;
    width: 100%;
    overflow-x: auto;
    border-spacing: 0;
    border-collapse: collapse;
    vertical-align: top;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    td {
      width: 100%;
      &:first-child {
        
      }
      &:last-child {
        
      }
    }
    tr{
      width: 100%;
    }
    th {
      width: 100%;
      color: #A9A9A9;
      font-size: 18px;;
      font-weight: 700;
      letter-spacing: 0.2px;
      text-align: left;
      text-transform: uppercase;
      background-color: #F5F5F5;
      padding: 6px 12px;
      border: #F5F5F5;
    }
    td {
      padding: 6px 12px;
      border: #F5F5F5 1px solid;
    }
  }



