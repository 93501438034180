// blog

.blog {
  margin-top: 20px;
  @media screen and (max-width: 39.9375em) {
    margin-top: 10px;
  }
  .post-card {
    margin-bottom: 20px;
    background-color: #8CC63F;

    @media screen and (max-width: 39.9375em) {
      margin-bottom: 10px;

    }
    .image-preview {
      width: 100%;
      height: 200px;
      max-height: 200px;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      background-color: #70777a !important;
      background-image: url("../img/background-images/no-preview.jpg");

      &:hover {

      }
    }
    &:hover {
      transition: .5s;
      background-color: #A3D165;
    }
    .meta-info {

      padding: 10px;
      h4 {
        color: #000;
      }
      h1 {
        color: #FFF;
      }
      p {
        color: #000;
      }
      .author {
        color: #FFF;
      }
    }
    a {
      text-decoration: none;
    }
  }

  .pagination {
    background-color: #8CC63F;
    display: inline-block;
    width: 100%;
    @media screen and (max-width: 39.9375em) {
      margin-bottom: 10px;
    }

    a {
      padding: 5px 10px 5px 10px;
      color: #1779ba;
    }

    .newer-posts {
      float: left;
    }
    .older-posts {
      float: right;
    }

    span {
      padding: 5px;

      float: left;
    }
  }
}

.blog-title {
  color: #FFF;
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (max-width: 39.9375em) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.blog-author {
  padding-left: 50px;
  padding-right: 50px;
  color: #FFFFFF;
  @media screen and (max-width: 39.9375em) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.blog-title-image {
  display: block;
  max-width: 100%;
  margin: 1.5em auto;
}

.blog-backlink{
  padding-top: 10px;
  margin-bottom: 0;
  a {
    &:hover{
      text-decoration: underline;
    }
  }
}

.post-full-content {
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: 39.9375em) {
      padding-left: 20px;
      padding-right: 20px;
    }


  h1, h2, h3, h4, h5, h6, p, ul, ol, dl, pre, blockquote {
    min-width: 100%;
    
  }
}

.post-full-comments, .footnotes {
  min-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (max-width: 39.9375em) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.post-full-content li {
  word-break: normal;
  p {
    margin: 0;
  }
}

.post-full-content {

  p {
    &:first-child {
      font-size: 1.25em;
      line-height: 1.5em;
    }
  }

  li {
    p {
      &:first-child {
        font-size: 15px;
        line-height: 1.5em;
      }
      font-size: 15px;
    }
  }

  a {
    color: #1779ba;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  strong, em {
    color: #000000;
  }
  small {
    display: inline-block;
    line-height: 1.6em;
  }
  li:first-child {
    margin-top: 0;
  }
  img, video {
    display: block;
    max-width: 80%;
    margin: 1.5em auto;
    @media screen and (max-width: 39.9375em) {
      max-width: 100%;
    }
  }
  
  iframe {
    margin: 0 auto;
    width: 100%;
    height: 500px;
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: 39.9375em) {
      height: 300px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  
  code {
    padding: 0 5px 2px;
    line-height: 1em;
    font-weight: 400 !important;
    background: #F5F5F5;
  }
  pre {
    max-width: 100%;
    overflow-x: auto;
    margin: 1.5em 0 3em;
    color: #F5F5F5;
    line-height: 1.5em;
    background: #909090;
    code {
      padding: 0;
      font-size: inherit;
      line-height: inherit;
      background: transparent;
    }
  }
  .fluid-width-video-wrapper {
    margin: 1.5em 0 3em;
  }
  hr {
    margin: 20px 10px 20px 10px;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #FFFFFF;
  }
  
}

@media (max-width: 1040px) {
  .post-full-content {
    img, video {
      width: 100%;
    }
  }
}

.footnotes-sep {
  margin-bottom: 20px;
}

.footnotes {
  font-size: 15px;
  p {
    margin: 0;
  }
  .footnotes-list {
    margin-left: 0;
    p {
      font-size: 15px;
    }
  }
}

.footnote-backref {
  color: #1779ba !important;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none !important;
  box-shadow: none !important;
}

/* Some grouped styles for smaller viewports */
@media (max-width: 500px) {
  .site-main {
    padding: 0;
  }
  .post-full-meta {
    font-size: 1.2rem;
    line-height: 1.3em;
  }
  .post-full-title {
    font-size: 4rem;
  }
  /*.post-full-image {
      height: 350px;
      margin-bottom: 4vw;
  }*/
  .post-full-content {
    padding: 15px;
  }
  .post-full-footer {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .post-full-content {
    &:before, &:after {
      display: none;
    }
  }
}